import { gsap } from "gsap";
export const mapAnimation = () => {
  const mapElement = document.querySelector(".p-point__map");
  const mapMain = document.querySelectorAll(".p-point__map--main");
  const mapPins = document.querySelectorAll(".p-point__map--pin img");

  // Intersection Observerの設定
  const observerOptions = {
    root: null,
    rootMargin: '-20%',
    threshold: 0
  };

  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        // 要素がビューポートに入った時の処理
        gsap.to(
          mapMain, 
          { 
            opacity: 1,
            duration: .4,
            ease: 'Power1.easeIn',
          }
        );

        gsap.to(
          mapPins, 
          { 
            y: 18,
            opacity: 1,
            delay: .2,
            stagger: .2,
            duration: .25,
            ease: 'Power1.easeOut',
          }
        );

        // Intersection Observerを解除する
        observer.unobserve(entry.target);
      }
    });
  };

  // Intersection Observerのインスタンスを生成
  const observer = new IntersectionObserver(observerCallback, observerOptions);

  // 監視対象の要素を設定
  observer.observe(mapElement);
};
