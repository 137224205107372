export const newsAccordion = () => {
  // 各要素を囲う親要素を取得
  const parentWrapper = document.querySelector(".js-newsWrapAccordion");
  // 親要素開閉のボタンを取得
  const buttonElement = document.querySelector(".js-newsWrapAccordionButton");
  // 各子要素を取得
  const accordionElements = document.querySelectorAll(".js-newsAccordionTitle");
  const accordionElementsArray = [...accordionElements]

  let parentStatus; // Declare the variable outside of the if statement

  if(parentWrapper) {
    // 親要素のステータスを取得
    parentStatus = parentWrapper.dataset.accordion; // Assign the value inside the if statement
  }

  const calculateDefaultHeight = () => {
    // 子要素が4つ以上ある場合に発火・PHPでも同様の処理実施
    if (accordionElementsArray.length > 3) {
      // 3つ目までの高さを取得
      const accordionDefault = accordionElementsArray.slice(0, 3);
      let heightArray = [];
      accordionDefault.forEach((element) => {
        heightArray.push(element.scrollHeight + 2);
      });
      const defaultHeight = heightArray.reduce((sum, num) => sum + num, 0);
      // borderの高さを加味
      parentWrapper.style.maxHeight = `${defaultHeight + 2}px`;
    }
  }

  const calculateFullHeight = () => {
      // 全ての子要素の高さを取得
      let fullHeightArray = [];
      accordionElementsArray.forEach((element) => {
        fullHeightArray.push(element.scrollHeight + 2);
      });
      const defaultFullHeight = fullHeightArray.reduce((sum, num) => sum + num, 0);
      // borderの高さを加味
      parentWrapper.style.maxHeight = `${defaultFullHeight + 2}px`;
  }

  // 親要素の高さを定義する関数
  const parentWrapperHeight = () => {
    if (parentStatus === 'close') {
      calculateDefaultHeight();
    }
    if (parentStatus === 'open') {
      calculateFullHeight();
    }
  }

  // 親要素のステータスをチェックする関数
  const toggleParentStatus = () => {
    parentWrapper.dataset.accordion = parentStatus === 'close' ? 'open' : 'close';
    // datasetから直接最新のステータスを取得して更新
    parentStatus = parentWrapper.dataset.accordion;
    parentWrapperHeight();
  }

  //子要素がクリックされたときの処理
  accordionElementsArray.forEach((element) => {
    const newsPanel = element.querySelector('.js-newsPanel')
    let newsPanelHeight = newsPanel.scrollHeight
    window.addEventListener('resize', () => {
      newsPanelHeight = newsPanel.scrollHeight
    });

    const accordionElementsButton = element.querySelector('.p-news-newsmain');
    accordionElementsButton.addEventListener("click", () => {
      let elementStatus = element.dataset.news;
      if (elementStatus === 'close') {
        element.dataset.news = 'open'
        newsPanel.style.maxHeight = `${newsPanelHeight}px`;
      }
      if (elementStatus === 'open') {
        element.dataset.news = 'close'
        newsPanel.style.maxHeight = `0`;
      }
      parentWrapperHeight();
    });
  });

  // 親要素開閉のボタンがクリックされたときの処理
  if(buttonElement) {
    buttonElement.addEventListener("click", () => {
      toggleParentStatus();
      buttonElement.classList.toggle("is-active");
    });
  }

  // 初回読み込み時に親要素の高さを計算
  parentWrapperHeight();
  // 画面幅リサイズ時に親要素の高さを計算
  window.addEventListener('resize', () => {
    parentWrapperHeight();
  });
};
