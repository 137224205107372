import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
export const menuSlider = () => {
  let menuContent = new Splide( '.js-menuContent', {
    type        : 'loop',
    pagination : false,
    arrows     : true,
    cover      : true,
    drag       : false,
    breakpoints: {
      768: {
        drag       : true,
        wheel      : false,
        pagination : true,
        waitForTransition: false,
      },
    }
  } );
  
  let menuNavigation = new Splide( '.js-menuNavigation', {
    rewind          : true,
    isNavigation    : true,
    focus           : 'center',
    pagination      : false,
    arrows          : false,
    cover           : true,
    drag       : false,
  } );
  
  menuContent.sync( menuNavigation );
  menuContent.mount();
  menuNavigation.mount();
}