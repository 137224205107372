import { gsap } from "gsap";
export const watchAnimation = () => {
  const watchElement = document.querySelector(".p-point__watch");
  const watchMain = document.querySelector(".p-point__watch--main");
  const watchBatch = document.querySelector(".p-point__watch--batch");

  const observerOptions = {
    root: null,
    rootMargin: '-20%',
    threshold: 0
  };

  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        gsap.to(
          watchMain, 
          { 
            opacity: 1,
            duration: .4,
            ease: 'Power1.easeIn',
          }
        );

        gsap.to(
          watchBatch, 
          { 
            y: -5,
            x: 20,
            scale: 1,
            opacity: 1,
            duration: 1.2,
            ease: 'Power1.easeInOut',
          }
        );
        observer.unobserve(entry.target);
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);
  observer.observe(watchElement);
};
