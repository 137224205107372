export const menuModal = () => {
  const menuContent = document.querySelector('.js-menuContent');
  let menuContentStatus = menuContent.dataset.menu;

  const menuNavigationButton = document.querySelectorAll('.js-menuNavigationButton');
  const menuCloseButton = document.querySelectorAll('.js-menuCloseButton');

  const menuModalOpen = () => {
    if (menuContentStatus === 'disable') {
      menuContent.dataset.menu = 'visible';
      // datasetから直接最新のステータスを取得して更新
      menuContentStatus = menuContent.dataset.menu;
    }
  }
  
  const menuModalClose = () => {
    if (menuContentStatus === 'visible') {
      menuContent.dataset.menu = 'disable';
      // datasetから直接最新のステータスを取得して更新
      menuContentStatus = menuContent.dataset.menu;
    }
  }

  menuNavigationButton.forEach((element) => {
    element.addEventListener('click', () => {
      menuModalOpen();

      let menuContentTop = menuContent.getBoundingClientRect().top;
      if (window.innerWidth <= 768) {
        menuContentTop = menuContentTop - 80;
      } else {
        menuContentTop = menuContentTop - 136;
      }
      window.scrollBy({
        top: menuContentTop,
        behavior: 'smooth',
      });
    });
    
  });

  menuCloseButton.forEach((element) => {
    element.addEventListener('click', () => {
      menuModalClose();
    });
  });
}