import { gsap } from "gsap";
export const countUp = () => {
    const countUpTrigger = document.querySelectorAll('.js-countUpTrigger');

    const observerOptions = {
        root: null,
        rootMargin: '-20%',
        threshold: 0
    };

    const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const countUpTarget = entry.target.querySelector('.js-countUpNumber');
                const elementDic = {
                    from: parseInt(countUpTarget.dataset.from),
                    to: parseInt(countUpTarget.dataset.to),
                    duration: parseInt(countUpTarget.dataset.duration)
                };
                const elementNum = { count: elementDic.from };
                gsap.to(elementNum, {
                    count: elementDic.to,
                    duration: elementDic.duration ? elementDic.duration / 1000 : 1,
                    ease: 'none',
                    onUpdate: () => {
                        countUpTarget.textContent = Math.floor(elementNum.count);
                    }
                });
                observer.unobserve(entry.target);
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    countUpTrigger.forEach(element => {
        observer.observe(element);
    });
};
