export const mvVideo = () => {
  // ページ内のビデオ要素を取得
  const video = document.querySelector(".js-mvVideo");

  function updateVideoSource(video, baseSrc) {
    // 現在のウィンドウサイズに基づいて接尾辞を選択
    const suffix = window.innerWidth <= 768 ? "_sp" : "_pc";
    const sourceMP4 = document.createElement("source");

    // 新しいソースファイルのパスを設定
    sourceMP4.setAttribute("src", baseSrc + suffix + ".mp4");
    sourceMP4.setAttribute("type", "video/mp4");

    // 既存のビデオソースをクリアし、新しいソースを追加
    video.innerHTML = "";
    video.appendChild(sourceMP4);
  }

    const baseSrc = video.getAttribute("data-src");
    updateVideoSource(video, baseSrc);

  // ウィンドウの横幅が変更されたときにのみビデオソースを更新
  let currentSize = window.innerWidth;
  window.addEventListener("resize", function () {
    const newSize = window.innerWidth;
    if (currentSize !== newSize) {
      currentSize = newSize;
      const baseSrc = video.getAttribute("data-src");
      updateVideoSource(video, baseSrc);
    }
  });
};