import { gsap } from "gsap";
export const promotionAnimation = () => {
  const promotionElement = document.querySelector(".p-point__promotion");
  const promotionMain = document.querySelector(".p-point__promotion--main");
  const promotionPerson = document.querySelector(".p-point__promotion--person");

  const promotionGraphs = document.querySelectorAll(".p-point__promotion-graph-content");

  const promotionGraphYushikisha = document.querySelector(".js-yushikishaCircle");

  const rLengthYushikisha = 2 * Math.PI * promotionGraphYushikisha.getAttribute("r");
  promotionGraphYushikisha.style.strokeDasharray = rLengthYushikisha;
  promotionGraphYushikisha.style.strokeDashoffset = rLengthYushikisha;

  const promotionGraphF1F2 = document.querySelector(".js-f1f2Circle");

  if(promotionGraphF1F2) {
    const rLengthF1F2 = 2 * Math.PI * promotionGraphF1F2.getAttribute("r");
    promotionGraphF1F2.style.strokeDasharray = rLengthF1F2;
    promotionGraphF1F2.style.strokeDashoffset = rLengthF1F2;
  }

  const observerOptions = {
    root: null,
    rootMargin: '-20%',
    threshold: 0
  };

  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        gsap.to(
          promotionMain, 
          {
            opacity: 1,
            duration: .4,
            ease: 'Power1.easeIn',
          }
        );

        gsap.to(
          promotionPerson, 
          {
            x: -20,
            scale: 1,
            opacity: 1,
            duration: 1.2,
            ease: 'Power1.easeInOut',
          }
        );

        gsap.to(
          promotionGraphs, 
          { 
            y: -20,
            opacity: 1,
            stagger: .25,
            duration: 1.4,
            ease: 'Power1.easeOut',
          }
        );

        gsap.to(promotionGraphYushikisha, {
            duration: 1,
            delay: .25,
            strokeDashoffset: .15 * rLengthYushikisha,
            ease: 'Power1.easeOut',
        })

        if(promotionGraphF1F2) {
          gsap.to(promotionGraphF1F2, {
              duration: 1,
              delay: .25,
              strokeDashoffset: .38 * rLengthF1F2,
              ease: 'Power1.easeOut',
          })
        }

        observer.unobserve(entry.target);
      }
    });
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);
  observer.observe(promotionElement);
};
