import { mvVideo } from './module/video/mvVideo';
mvVideo();

import { mapAnimation } from './module/point/mapAnimation';
mapAnimation();

import { watchAnimation } from './module/point/watchAnimation';
watchAnimation();

import { promotionAnimation } from './module/point/promotionAnimation';
promotionAnimation();

import { countUp } from './module/point/countUp';
countUp();

import { newsAccordion } from './module/accordion/newsAccordion';
newsAccordion();

import { menuSlider } from './module/menu/menuSlider';
menuSlider();
import { menuModal } from './module/menu/menuModal';
menuModal();

import { aboutSlider } from './module/about/aboutSlider';
aboutSlider();
