import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Splide from '@splidejs/splide';
import '@splidejs/splide/css';
export const aboutSlider = () => {

  let aboutSlide = new Splide( '.js-aboutSlide', {
    pagination : false,
    arrows     : false,
    drag       : false,
    breakpoints: {
      992: {
        drag       : true,
        wheel      : false,
        pagination : true,
        waitForTransition: false,
        autoplay   : true,
        type       : 'loop',
      },
    }
    } 
  );

  aboutSlide.mount();

  window.addEventListener('resize', function() {
    if (window.innerWidth >= 993) {
      aboutSlide.go(0);
    }
  });

  const container = document.querySelector('.js-aboutContainer');
  const slides = gsap.utils.toArray('.js-aboutItem');
  const getContainerWidth = () => {
    const container = document.querySelector('.js-aboutContainer');
    return container.offsetWidth;
  };

  const containerWidth = getContainerWidth();

  const progressContent = document.querySelector('.js-progress');
  const progressCircle = progressContent.querySelectorAll('span');

  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.matchMedia({
    "(min-width: 993px)": function() {
      gsap.to(slides, {
        x: -100 * 2 - 32 + "%",
        ease: 'none',
        scrollTrigger: {
          trigger: container,
          pin: true,
          scrub: true,
          start: "top top",
          end: () => "+=" + containerWidth,
          anticipatePin: 1,
          invalidateOnRefresh: true,
          snap: {
            snapTo: 1 / (slides.length - 1),
          },
          onUpdate: self => {
            const progress = self.progress; // スクロールの進行状況を取得
            const pageIndex = Math.round(progress * (slides.length - 1)); // 現在のページ番号を計算
            progressCircle.forEach((circle, index) => {
              if (index === pageIndex) {
                circle.classList.add('is-active');
              } else {
                circle.classList.remove('is-active');
              }
            });
          } 
        }
      });
    }
  });
  
}